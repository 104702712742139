import http from '@/api/http';

const updateRefreshToken = () => {
	return http.post('/auth/refresh');
};

const socialLogin = (token: string, fcmToken: any) => {
	return http.post('/auth/social', {
		token,
		fcmToken,
	});
};

//
export const handleSendVerificationCodeApi = (payload:any) => http.post('/auth/phoneCode/send', payload)

export const checkVerificationCodeApi = (payload:any)=>http.post('/auth/phoneCode/verify', payload)



export default {
	updateRefreshToken,
	socialLogin,
};
